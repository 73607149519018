import { computed, ref } from "vue";
import { Buffer } from "buffer";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import { useField, useForm } from "vee-validate";

export default function usePollerConfig() {
  const TAGS = {
    POLLING: "85",
    C9: "C9",
    EA: "EA",
    MENU: "80",
  } as const;
  enum PollingType {
    OnlyLaunched = 0,
    Minutes = 1,
    Hours = 2,
    Disabled = 3,
  }
  const { t } = useI18n();
  const numberErrorMessages = computed(() => {
    return {
      hours: t("ota.validate.invalidNumber", { min: 0, max: 99 }),
      minutes: t("ota.validate.invalidNumber", { min: 0, max: 59 }),
    };
  });
  const getTestConfig = (name: string, errorMessage: string, min: number, max: number) => {
    const testConfig: Yup.TestConfig<string | number | undefined> = {
      name,
      test: function (value) {
        if (`${Number(value)}` !== value || Number(value) < min || Number(value) > max) {
          return false;
        }
        return true;
      },
      message: errorMessage,
    };
    return testConfig;
  };
  const schema = Yup.object({
    reportIntervalHours: Yup.string()
      .required(t("ota.validate.Required", { label: t("ota.header.hours") }))
      .test(getTestConfig("InvalidHour", numberErrorMessages.value.hours, 0, 99)),
    reportIntervalMinutes: Yup.string()
      .required(t("ota.validate.Required", { label: t("ota.header.minutes") }))
      .test(getTestConfig("InvalidMinutes", numberErrorMessages.value.minutes, 0, 59)),
  });
  const { meta } = useForm({
    validationSchema: schema,
    initialValues: {
      pollingType: PollingType.Minutes,
      reportIntervalHours: 0,
      reportIntervalMinutes: 5,
    },
  });
  const { value: reportIntervalHours, errorMessage: reportIntervalHoursError } = useField<number>("reportIntervalHours");
  const { value: reportIntervalMinutes, errorMessage: reportIntervalMinutesError } = useField<number>("reportIntervalMinutes");
  const pollingType = ref(PollingType.Minutes);
  const pollingTypeOptions = computed(() => {
    return [
      { value: PollingType.Minutes, label: t("ota.EnablePolling") },
      { value: PollingType.OnlyLaunched, label: t("ota.OnlyLaunched") },
      { value: PollingType.Disabled, label: t("ota.DisablePolling") },
    ];
  });
  const isEnablePolling = computed(() => [PollingType.Minutes, PollingType.Hours].includes(pollingType.value));
  const isEnableMenuDisplay = ref(true);
  const _reportIntervalHoursError = computed(() => {
    if (!isEnablePolling.value) {
      return;
    }
    if (reportIntervalHoursError.value) {
      return reportIntervalHoursError.value;
    }
    if (String(reportIntervalHours.value) === "0" && String(reportIntervalMinutes.value) === "0") {
      return t("ota.validate.InvalidIntervalTotalValue");
    }
    if (!/^[0-9]*$/.test(String(reportIntervalHours.value))) {
      return t("ota.validate.InvalidInt");
    }
    return undefined;
  });
  const _reportIntervalMinutesError = computed(() => {
    if (!isEnablePolling.value) {
      return;
    }
    if (reportIntervalMinutesError.value) {
      return reportIntervalMinutesError.value;
    }
    if (String(reportIntervalHours.value) === "0" && String(reportIntervalMinutes.value) === "0") {
      return t("ota.validate.InvalidIntervalTotalValue");
    }
    if (!/^[0-9]*$/.test(String(reportIntervalMinutes.value))) {
      return t("ota.validate.InvalidInt");
    }
    return undefined;
  });
  const isValid = computed(() => (!isEnablePolling.value ? !_reportIntervalMinutesError.value && !_reportIntervalHoursError.value : true));

  const isHex = (input: string): boolean => {
    const res = input.match(/[0-9a-fA-F]*/);
    if (res && res[0] === input && input.length % 2 === 0) {
      return true;
    }
    return false;
  };

  const initByInstallParameters = (params: string, swapReportInterval = false) => {
    if (!params || !isHex(params)) {
      return;
    }
    const buffer = Buffer.from(params, "hex");
    if (buffer[0].toString(16).toUpperCase() !== TAGS.C9) {
      return;
    }
    // NOTE: tagとlengthの長さ
    const tlLength = 2;
    const len = buffer[1];
    const data = buffer.slice(tlLength, len + tlLength);
    parseApplicationSpecificParameters(data, len, swapReportInterval);

    const eaIndex = buffer.findIndex((value) => value.toString(16).toUpperCase() === TAGS.EA);
    if (eaIndex >= 0) {
      const len = buffer[eaIndex + 1];
      const data = buffer.slice(eaIndex + tlLength, len + tlLength);
      parseEAParameters(data, len);
    }
  };

  const parseEAParameters = (params: Buffer, length: number) => {
    let len = 0;
    const tlLen = 2;
    for (let offset = 0; offset < length; offset += len + tlLen) {
      const tag = params[offset].toString(16).toUpperCase();
      len = parseInt(params[offset + 1].toString(), 10);
      const data = params.slice(offset + tlLen, offset + len + tlLen);
      switch (tag) {
        case TAGS.MENU: {
          // NOTE: 4つ目(インデックスが3)の値がメニュー表示数
          const menus = data[3];
          isEnableMenuDisplay.value = menus > 0;
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const parseApplicationSpecificParameters = (params: Buffer, length: number, swapReportInterval = false) => {
    let len = 0;
    const tlLen = 2;
    for (let offset = 0; offset < length; offset += len + tlLen) {
      const tag = params[offset].toString(16).toUpperCase();
      len = parseInt(params[offset + 1].toString(), 10);
      const data = params.slice(offset + tlLen, offset + len + tlLen);
      switch (tag) {
        case TAGS.POLLING: {
          const _pollingType = data[0];
          if (len === 3) {
            if (_pollingType === PollingType.OnlyLaunched || _pollingType === PollingType.Disabled) {
              // 初回起動時のみもしくは完全に無効の場合
              pollingType.value = _pollingType;
              reportIntervalHours.value = 0;
              reportIntervalMinutes.value = 0;
            } else if (_pollingType === PollingType.Minutes) {
              pollingType.value = _pollingType;
              // ポーリングを周期をminutesで指定している場合
              const totalMinutes = parseInt(data[1].toString(16) + data[2].toString(16), 16);
              // 時間と分に分割
              const hours = Math.floor(totalMinutes / 60);
              reportIntervalHours.value = hours;
              reportIntervalMinutes.value = totalMinutes - hours * 60;
            } else if (_pollingType === PollingType.Hours) {
              pollingType.value = _pollingType;
              // ポーリング周期をhoursで指定している場合
              reportIntervalHours.value = parseInt(data[1].toString(16) + data[2].toString(16), 16);
              reportIntervalMinutes.value = 0;
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const buildPollingValue = () => {
    /**
     * 1桁目: pollingの有無・周期の単位を指定
     *  0: 端末起動時のみpollingを行う
     *  1: 指定周期でpollingを行う(単位=分)
     *  2: 指定周期でpollingを行う(単位=hours)(UI的に使用してない)
     *  3: 起動時含めpollingを全くしない
     * 2・3桁目: polling周期
     *  値域は0以上32767以下
     *  0 ~ 32767を16進数に変換する
     */
    let pollingValue = String(pollingType.value).padStart(2, "0");
    const totalMinutes = Number(reportIntervalHours.value) * 60 + Number(reportIntervalMinutes.value);
    const enablePolling = [PollingType.Minutes, PollingType.Hours].includes(pollingType.value);
    pollingValue += enablePolling ? totalMinutes.toString(16).padStart(4, "0") : "0000";
    return pollingValue;
  };

  const buildInstallParameters = () => {
    /** afterC9Paramsの設定値
     * Toolkit アプリケーション インスタンスの優先度: 255
     * タイマーの数: 5
     * チャネルの数: 5
     * メニューに表示するエントリーの数:
     *    isEnableMenuDisplay.valueがtrue: 6
     *    isEnableMenuDisplay.valueがfalse: 0
     * メニュー エントリーの文字数: 32
     * ADF AID: A0000000871002FFFFFFFF8903020000
     * Volatile Memory Quota(C7): 1
     * タイマーの数: 1
     */
    const afterC9Params = isEnableMenuDisplay.value
      ? "EA2C8010FF01200501000200030004000500020081180001000010A0000000871002FFFFFFFF8903020000010000"
      : "EA228006FF012000050081180001000010A0000000871002FFFFFFFF8903020000010000";
    let c9Value = "";
    c9Value += buildTLV(TAGS.POLLING, buildPollingValue());
    const c9TLV = buildTLV(TAGS.C9, c9Value);
    return `${c9TLV}${afterC9Params}`.toUpperCase();
  };

  const padding = (value: string | number): string => {
    return `${value}`.padStart(2, "0");
  };

  const buildTLV = (tag: string, value: string | number): string => {
    const len = `${value}`.length / 2;
    return `${tag}${padding(len.toString(16))}${value}`;
  };

  return {
    meta,
    reportIntervalHours,
    reportIntervalHoursError: _reportIntervalHoursError,
    reportIntervalMinutes,
    reportIntervalMinutesError: _reportIntervalMinutesError,
    initByInstallParameters,
    isValid,
    buildInstallParameters,
    isEnablePolling,
    pollingType,
    PollingType,
    pollingTypeOptions,
    isEnableMenuDisplay,
  };
}
